<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-06-15 19:58:16
-->
<template>
  <div class="login-container"></div>
</template>

<script>
import { setToken } from '@/util/auth'
import { getUrlKey } from '@/util/common.js'
export default {
  name: 'Login',
  data() {
    return {}
  },
  created() {
    let authToken = getUrlKey('token')
    console.log('%c [ authToken ]-21', 'font-size:13px; background:#08e71f; color:#4cff63;', authToken)
    this.secretFreeLogin(authToken)
  },
  methods: {
    secretFreeLogin(authToken) {
      setToken(authToken, '')
      this.$store.dispatch('GetInfo').then((res) => {
        this.$router.replace({ path: '/' })
      })
    },
  },
}
</script>
